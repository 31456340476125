// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#SpaceTimeCube_SpaceTimeCube__7p4qv {\n    flex: 0.5 1;\n}\n\n#SpaceTimeCube_SpaceTimeCubePreconf__qUVFD {\n    width: 50vw;\n}", "",{"version":3,"sources":["webpack://./src/components/SpaceTimeCube/SpaceTimeCube.module.css"],"names":[],"mappings":"AAAA;IACI,WAAS;AACb;;AAEA;IACI,WAAW;AACf","sourcesContent":["#SpaceTimeCube {\n    flex: 0.5;\n}\n\n#SpaceTimeCubePreconf {\n    width: 50vw;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SpaceTimeCube": "SpaceTimeCube_SpaceTimeCube__7p4qv",
	"SpaceTimeCubePreconf": "SpaceTimeCube_SpaceTimeCubePreconf__qUVFD"
};
export default ___CSS_LOADER_EXPORT___;
