// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#SpeedSelector_Selector__ICJto {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n}\n\n.SpeedSelector_name__S51X7 {\n    padding: 0 7px;\n}\n\n.SpeedSelector_label__BSOPL{\n    font-weight: bold;\n}\n\n.SpeedSelector_icon__2nqOi {\n    cursor: pointer;\n}\n\n.SpeedSelector_wrapper__Cdkze {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: space-evenly;\n    width: 40%;\n    font-size: 13px;\n    padding-bottom: 10px;\n\n    -webkit-touch-callout: none;\n    -webkit-user-select: none;\n    user-select: none;\n}", "",{"version":3,"sources":["webpack://./src/components/SpeedSelector/SpeedSelector.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,6BAA6B;IAC7B,UAAU;IACV,eAAe;IACf,oBAAoB;;IAEpB,2BAA2B;IAC3B,yBAAyB;IAIzB,iBAAiB;AACrB","sourcesContent":["#Selector {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n}\n\n.name {\n    padding: 0 7px;\n}\n\n.label{\n    font-weight: bold;\n}\n\n.icon {\n    cursor: pointer;\n}\n\n.wrapper {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: space-evenly;\n    width: 40%;\n    font-size: 13px;\n    padding-bottom: 10px;\n\n    -webkit-touch-callout: none;\n    -webkit-user-select: none;\n    -khtml-user-select: none;\n    -moz-user-select: none;\n    -ms-user-select: none;\n    user-select: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Selector": "SpeedSelector_Selector__ICJto",
	"name": "SpeedSelector_name__S51X7",
	"label": "SpeedSelector_label__BSOPL",
	"icon": "SpeedSelector_icon__2nqOi",
	"wrapper": "SpeedSelector_wrapper__Cdkze"
};
export default ___CSS_LOADER_EXPORT___;
