// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#Map_Map__zZzYW {\n    flex: 1 1;\n    position: relative;\n}\n\n#Map_MapPreconf__MZj1A {\n    width: 50vw;\n    position: relative;\n}\n\n.Map_dateTag__FHjS2 {\n    position: absolute;\n    bottom: 100px;\n    right: 20%; \n    margin: auto;\n    z-index: 10000;\n    font-size: 16px;\n    color: #eee;\n    font-weight: bold;\n}\n\n.Map_legendContainer__fECak {\n    position: absolute;\n    width: 100%;\n    bottom: 0;\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    z-index: 100;\n    padding: 10px;\n}", "",{"version":3,"sources":["webpack://./src/components/Map/Map.module.css"],"names":[],"mappings":"AAAA;IACI,SAAO;IACP,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,UAAU;IACV,YAAY;IACZ,cAAc;IACd,eAAe;IACf,WAAW;IACX,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,SAAS;IACT,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,YAAY;IACZ,aAAa;AACjB","sourcesContent":["#Map {\n    flex: 1;\n    position: relative;\n}\n\n#MapPreconf {\n    width: 50vw;\n    position: relative;\n}\n\n.dateTag {\n    position: absolute;\n    bottom: 100px;\n    right: 20%; \n    margin: auto;\n    z-index: 10000;\n    font-size: 16px;\n    color: #eee;\n    font-weight: bold;\n}\n\n.legendContainer {\n    position: absolute;\n    width: 100%;\n    bottom: 0;\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    z-index: 100;\n    padding: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Map": "Map_Map__zZzYW",
	"MapPreconf": "Map_MapPreconf__MZj1A",
	"dateTag": "Map_dateTag__FHjS2",
	"legendContainer": "Map_legendContainer__fECak"
};
export default ___CSS_LOADER_EXPORT___;
