// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Legend_Legend__Xh126 {\n    font-size: 13px;\n    margin-bottom: 10%;\n}\n\n.Legend_minMax__c-VrZ {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n\n    transform: translateY(15px);\n}\n\n.Legend_title__803hu{\n\n    color:black;\n}\n.Legend_min__PwEC0,\n.Legend_max__owgzG {\n    padding-left: 10px;\n    display: flex;\n    flex-direction: column;\n    color: black;\n    align-items: center;\n}\n\n.Legend_min__PwEC0 {\n    transform: translateX(-50%);\n}\n\n.Legend_max__owgzG {\n    transform: translateX(50%);\n}\n\n.Legend_line__i8cdR {\n    border-left: 1px solid black;\n    height: 20px;\n    width: 0;\n}", "",{"version":3,"sources":["webpack://./src/components/Legend/Legend.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;;IAE9B,2BAA2B;AAC/B;;AAEA;;IAEI,WAAW;AACf;AACA;;IAEI,kBAAkB;IAClB,aAAa;IACb,sBAAsB;IACtB,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,4BAA4B;IAC5B,YAAY;IACZ,QAAQ;AACZ","sourcesContent":[".Legend {\n    font-size: 13px;\n    margin-bottom: 10%;\n}\n\n.minMax {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n\n    transform: translateY(15px);\n}\n\n.title{\n\n    color:black;\n}\n.min,\n.max {\n    padding-left: 10px;\n    display: flex;\n    flex-direction: column;\n    color: black;\n    align-items: center;\n}\n\n.min {\n    transform: translateX(-50%);\n}\n\n.max {\n    transform: translateX(50%);\n}\n\n.line {\n    border-left: 1px solid black;\n    height: 20px;\n    width: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Legend": "Legend_Legend__Xh126",
	"minMax": "Legend_minMax__c-VrZ",
	"title": "Legend_title__803hu",
	"min": "Legend_min__PwEC0",
	"max": "Legend_max__owgzG",
	"line": "Legend_line__i8cdR"
};
export default ___CSS_LOADER_EXPORT___;
