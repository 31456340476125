const datasetIndex = [
    {
        name: "Albatross",
        url: "../public_data/albatross-normal-tracks-9track-tailcrosswind.csv"
    },
    {
        name: "Tiger",
        url: ""
    }
];

export default datasetIndex;