// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Sponsor_img__eIAkw{\n    display: flex;\n    height: 10p;\n    justify-content: center;\n    padding-left: 10;\n}\n.Sponsor_Container__6ellJ{\n  width: 50%;\n}", "",{"version":3,"sources":["webpack://./src/components/NavItems/Sponsor.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,WAAW;IACX,uBAAuB;IACvB,gBAAgB;AACpB;AACA;EACE,UAAU;AACZ","sourcesContent":[".img{\n    display: flex;\n    height: 10p;\n    justify-content: center;\n    padding-left: 10;\n}\n.Container{\n  width: 50%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"img": "Sponsor_img__eIAkw",
	"Container": "Sponsor_Container__6ellJ"
};
export default ___CSS_LOADER_EXPORT___;
