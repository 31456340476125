// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#NewMap_Map_split__IMobS{\n   \n    /*margin-left: 18%;*/\n    /*margin-right:18%;*/\n    /*height: 100px;*/\n\n    \n    \n}\n#NewMap_Map__rPxnZ{\n/* \n    margin-left: 20%; */\n    /* margin-right:18%; */\n    /*height: 100px;*/\n\n    \n    \n}\n\n\n\n.NewMap_dateTag__Yu\\+zS {\n    position: absolute;\n    bottom: 12.5%;\n    right: 20%; \n    margin: auto;\n    z-index: 10000;\n    font-size: 16px;\n    color: black;\n    font-weight: bold;\n}\n\n.NewMap_legendTag__3RQqA { \n    position: absolute;\n    bottom: 12.5%;\n    right: 60%; \n    margin: auto;\n    z-index: 10000;\n    font-size: 16px;\n    color: #eee;\n    font-weight: bold;\n}", "",{"version":3,"sources":["webpack://./src/components/Map/NewMap.module.css"],"names":[],"mappings":"AAAA;;IAEI,oBAAoB;IACpB,oBAAoB;IACpB,iBAAiB;;;;AAIrB;AACA;AACA;uBACuB;IACnB,sBAAsB;IACtB,iBAAiB;;;;AAIrB;;;;AAIA;IACI,kBAAkB;IAClB,aAAa;IACb,UAAU;IACV,YAAY;IACZ,cAAc;IACd,eAAe;IACf,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,UAAU;IACV,YAAY;IACZ,cAAc;IACd,eAAe;IACf,WAAW;IACX,iBAAiB;AACrB","sourcesContent":["#Map_split{\n   \n    /*margin-left: 18%;*/\n    /*margin-right:18%;*/\n    /*height: 100px;*/\n\n    \n    \n}\n#Map{\n/* \n    margin-left: 20%; */\n    /* margin-right:18%; */\n    /*height: 100px;*/\n\n    \n    \n}\n\n\n\n.dateTag {\n    position: absolute;\n    bottom: 12.5%;\n    right: 20%; \n    margin: auto;\n    z-index: 10000;\n    font-size: 16px;\n    color: black;\n    font-weight: bold;\n}\n\n.legendTag { \n    position: absolute;\n    bottom: 12.5%;\n    right: 60%; \n    margin: auto;\n    z-index: 10000;\n    font-size: 16px;\n    color: #eee;\n    font-weight: bold;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Map_split": "NewMap_Map_split__IMobS",
	"Map": "NewMap_Map__rPxnZ",
	"dateTag": "NewMap_dateTag__Yu+zS",
	"legendTag": "NewMap_legendTag__3RQqA"
};
export default ___CSS_LOADER_EXPORT___;
