// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#FadeSelector_Fade__hWcRt {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n}\n\n.FadeSelector_name__2J9jY {\n    padding: 0 7px;\n}\n\n.FadeSelector_label__Yt6NB{\n    font-weight: bold;\n}\n\n.FadeSelector_icon__2bmJN {\n    cursor: pointer;\n}\n\n.FadeSelector_wrapper__cc9VK {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: space-evenly;\n    width: 40%;\n    font-size: 13px;\n    padding-right: 10px;\n\n    -webkit-touch-callout: none;\n    -webkit-user-select: none;\n    user-select: none;\n}", "",{"version":3,"sources":["webpack://./src/components/FadeSelector/FadeSelector.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,6BAA6B;IAC7B,UAAU;IACV,eAAe;IACf,mBAAmB;;IAEnB,2BAA2B;IAC3B,yBAAyB;IAIzB,iBAAiB;AACrB","sourcesContent":["#Fade {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n}\n\n.name {\n    padding: 0 7px;\n}\n\n.label{\n    font-weight: bold;\n}\n\n.icon {\n    cursor: pointer;\n}\n\n.wrapper {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: space-evenly;\n    width: 40%;\n    font-size: 13px;\n    padding-right: 10px;\n\n    -webkit-touch-callout: none;\n    -webkit-user-select: none;\n    -khtml-user-select: none;\n    -moz-user-select: none;\n    -ms-user-select: none;\n    user-select: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Fade": "FadeSelector_Fade__hWcRt",
	"name": "FadeSelector_name__2J9jY",
	"label": "FadeSelector_label__Yt6NB",
	"icon": "FadeSelector_icon__2bmJN",
	"wrapper": "FadeSelector_wrapper__cc9VK"
};
export default ___CSS_LOADER_EXPORT___;
