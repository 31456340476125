// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#ColorPicker_ColorPicker__UIB\\+T {\n    border: 1px solid black;\n    margin-left: 10px;\n    width: 17px;\n    height: 17px;\n    border-radius: 50%;\n}", "",{"version":3,"sources":["webpack://./src/components/ColorPicker/ColorPicker.module.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,iBAAiB;IACjB,WAAW;IACX,YAAY;IACZ,kBAAkB;AACtB","sourcesContent":["#ColorPicker {\n    border: 1px solid black;\n    margin-left: 10px;\n    width: 17px;\n    height: 17px;\n    border-radius: 50%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ColorPicker": "ColorPicker_ColorPicker__UIB+T"
};
export default ___CSS_LOADER_EXPORT___;
